







































































































































































































































































































import CUpload from "@/components/shared/upload/CUpload.vue";
import { isImg, transformDataURL } from "@/helpers/file-reader";
import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponseUploadData } from "@/models/interface/assets.interface";
import { formatterNumber, reverseFormatNumber } from "@/validator/globalvalidator";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import { DECIMAL_PLACES_CURRENCY } from "@constant/global.constant";
import { GENERAL_JOURNAL_SOURCE, GENERAL_JOURNAL_STATUS } from "@enum/general-journal.enum";
import { Mode } from "@enum/global.enum";
import { ResponseAccountingAccount } from "@interface/accounting-account.interface";
import { DataListJournalLines, RequestAccountingGeneralJournalLine, RequestGeneralJournalCreate } from "@interface/general-journal.interface";
import { fileServices } from "@service/file.service";
import { generalJournalServices } from "@service/general-journal.service";
import { Decimal } from "decimal.js-light";
import moment from "moment";
import Vue from "vue";
import VueEasyLightbox from "vue-easy-lightbox";

interface RowTable {
  key: number;
  accountId: string;
  accountCode: string
  code: string;
  description: string;
  debit: number;
  credit: number;
  id?: string;
  no: number;
}

export default Vue.extend({
  name: "CreateJournal",
  components: {
    CUpload,
    VueEasyLightbox,
    CSelectAccountingAccount: () => import("@/components/shared/select-accounting-account/CSelectAccountingAccount.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      DECIMAL_PLACES_CURRENCY,
      selectedRowKeys: [] as number[],
      mode: Mode.CREATE as string,
      form: {
        date: "",
        description: "",
        documentNumber: "",
        documentReference: "",
        source: "",
        status: "",
        attachment: null as string | null,
      },
      totalCreditValue: 0,
      totalDebitValue: 0,
      dataListJournalLines: [] as RowTable[],
      formProps: {
        date: {
          label: "lbl_date",
          name: "Date",
          placeholder: "lbl_choose",
        },
        description: {
          label: "lbl_description",
          name: "Description",
          placeholder: "lbl_type_here",
        },
        attachment: {
          label: "lbl_attachment",
          name: "Attachment",
          placeholder: "lbl_choose",
        },
      },
      formRules: {
        date: [{ required: true, message: () => this.$t("lbl_validation_required_error").toString() }],
        description: [{ required: true, message: () => this.$t("lbl_validation_required_error").toString() }],
        documentNumber: [{ required: false, message: () => this.$t("lbl_validation_required_error").toString() }],
        documentReference: [{ required: false, message: () => this.$t("lbl_validation_required_error").toString() }],
        source: [{ required: false, message: () => this.$t("lbl_validation_required_error").toString() }],
      },
      loading: {
        table: false,
        save: false,
        posted: false,
        deleteAttachment: false,
      },
      journalId: "",
      attachment: null as string | ArrayBuffer | null,
      showImage: false,
    };
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
    isModeUpdate(): boolean {
      return this.mode === Mode.EDIT;
    },
    isModeCreate(): boolean {
      return this.mode === Mode.CREATE;
    },
    isJournalBalance(): boolean {
      return this.getTotalDebit !== this.getTotalCredit;
    },
    getTotalDebit(): number {
      return this.dataListJournalLines.reduce((a, b) => new Decimal(b.debit || 0).plus(a).toNumber(), 0);
    },
    getTotalCredit(): number {
      return this.dataListJournalLines.reduce((a, b) => new Decimal(b.credit || 0).plus(a).toNumber(), 0);
    },
    isPosted(): boolean {
      return this.form.status === GENERAL_JOURNAL_STATUS.POSTED;
    },
    isSubmitted(): boolean {
      return this.form.status === GENERAL_JOURNAL_STATUS.SUBMITTED;
    },
  },
  created() {
    this.mode = this.$route.meta.mode;
    this.journalId = this.$route.params.id || "";
    if (this.journalId) {
      this.getDetailJournal(this.journalId);
    }
  },
  methods: {
    moment,
    isImg,
    formatterNumber,
    reverseFormatNumber,
    async deleteAttachment(): Promise<void> {
      if (!this.form.attachment) return;
      try {
        this.loading.deleteAttachment = true;
        await fileServices.deleteFile(this.form.attachment);
        this.showNotifSuccess("notif_file_delete_success");
        this.form.attachment = null;
        this.attachment = null;
      } catch (error) {
        this.showNotifError("notif_file_delete_fail");
      } finally {
        this.loading.deleteAttachment = false;
      }
    },
    toggleShowImage() {
      this.showImage = !this.showImage;
    },
    onUploadDone(res: ResponseUploadData): void {
      this.form.attachment = res.objectName;
    },
    onUploadRemoved(): void {
      this.form.attachment = null;
    },
    handlePosted(): void {
      const form: any = this.$refs.formCreateJournal;
      form.validate((valid: boolean) => {
        if (valid && this.validateTable()) {
          const payload = this.createPayload();
          if (this.journalId) {
            this.postingGeneralJournal(this.journalId, payload);
          }
        } else {
          this.$notification.warning({
            message: this.$t("lbl_warning_title").toString(),
            description: this.$t("notif_validation_error").toString(),
          });
        }
      });
    },
    async getAttachment(filename: string | null): Promise<void> {
      try {
        if (!filename) return;
        const blob = await fileServices.getFile(filename);
        this.attachment = await transformDataURL(blob);
      } catch (error) {
        this.showNotifError("notif_file_get_fail", { filename });
      }
    },
    async getDetailJournal(id: string): Promise<void> {
      try {
        this.loading.table = true;
        const res = await generalJournalServices.detailGeneralJournal(id);
        this.form = {
          date: res.date,
          description: res.description,
          documentNumber: res.name,
          documentReference: res.documentReference,
          source: res.source,
          status: res.status,
          attachment: res.attachment
        };
        this.getAttachment(this.form.attachment);
        this.fillTable(res.journalLineDTOS);
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.table = false;
      }
    },
    fillTable(lines: DataListJournalLines[]): void {
      this.dataListJournalLines = lines.map((line, i) => ({
        no: i + 1,
        key: i,
        accountId: line.accountId,
        accountCode: line.accountCode,
        code: line.accountCode,
        description: line.description,
        debit: line.debit,
        credit: line.credit,
        id: line.id,
      }));
    },
    disabledDate(current) {
      return current > this.moment().endOf("day");
    },
    handleBack() {
      this.$router.push({ name: "generaljournal.read" });
    },
    validateTable(): boolean {
      let valid = true;
      if (!this.dataListJournalLines.length) {
        valid = false;
      } else {
        const idx = this.dataListJournalLines.findIndex(x => !x.accountId || (!x.debit && !x.credit));
        valid = idx === -1;
      }
      return valid;
    },
    handleSave(): void {
      const form: any = this.$refs.formCreateJournal;
      form.validate((valid: boolean) => {
        if (valid && this.validateTable()) {
          const payload = this.createPayload();
          if (this.journalId) {
            this.updateGeneralJournal(this.journalId, payload);
          } else {
            this.createGeneralJournal(payload);
          }
        } else {
          this.$notification.warning({
            message: this.$t("lbl_warning_title").toString(),
            description: this.$t("notif_validation_error").toString(),
          });
        }
      });
    },
    createPayload(): RequestGeneralJournalCreate {
      const model = {...this.form};
      const lines: RequestAccountingGeneralJournalLine[] = this.dataListJournalLines.map(line => ({
        accountId: line.accountId,
        credit: line.credit,
        debit: line.debit,
        description: line.description,
        id: line.id,
      }));
      const payload: RequestGeneralJournalCreate = {
        conversionDTO: {
          currency: "IDR", // confirm
          defaultCurrencyRate: 1, // confirm
        },
        date: model.date,
        description: model.description,
        attachment: model.attachment ?? "",
        documentReference: "",
        journalLineDTOS: lines,
        source: GENERAL_JOURNAL_SOURCE.MANUAL,
        total: this.getTotalDebit, // send debit or credit value
      };
      return payload;
    },
    postingGeneralJournal(id: string, payload: RequestGeneralJournalCreate): void {
      this.loading.posted = true;
      generalJournalServices.postingGeneralJournal(id, payload)
          .then(() => {
            this.$message.success(this.$t("notif_update_success").toString());
            this.$router.replace({ name: "generaljournal.detail", params: { id } });
          })
          .catch(() => this.$message.error(this.$t("notif_update_fail").toString()))
          .finally(() => this.loading.posted = false);
    },
    updateGeneralJournal(id: string, payload: RequestGeneralJournalCreate): void {
      this.loading.save = true;
      generalJournalServices.updateGeneralJournal(id, payload)
      .then(() => {
        this.$message.success(this.$t("notif_update_success").toString());
        this.getDetailJournal(id);
      })
      .catch(() => this.$message.error(this.$t("notif_update_fail").toString()))
      .finally(() => this.loading.save = false);
    },
    createGeneralJournal(payload: RequestGeneralJournalCreate): void {
      this.loading.save = true;
      generalJournalServices.createGeneralJournal(payload)
      .then(({ id }) => {
        this.$message.success(this.$t("notif_create_success").toString());
        this.$router.replace({ name: "generaljournal.update", params: { id } });
      })
      .catch(() => this.$message.error(this.$t("notif_submit_fail").toString()))
      .finally(() => this.loading.save = false);
    },
    onSelectChange(value) {
      this.selectedRowKeys = value;
    },
    showConfirmation() {
      if (this.selectedRowKeys.length > 0) {
        this.$confirm({
          title: this.$t("lbl_modal_delete_title_confirm").toString(),
          content: this.$t("lbl_modal_delete_info", {count: this.selectedRowKeys.length}).toString(),
          onOk: () => {
            this.handleDeleteRow();
          }
        });
      } else {
        this.$notification.error({
          message: this.$t("lbl_error_title").toString(),
          description: this.$t("lbl_modal_delete_error_description").toString(),
        });
      }
    },
    handleDeleteRow() {
      this.dataListJournalLines = this.dataListJournalLines.filter(
        (data) => !this.selectedRowKeys.includes(data["key"])
      );
      this.dataListJournalLines.forEach((data, index) => {
        data.key = index;
        data.no = index + 1;
      });
      this.dataListJournalLines = this.dataListJournalLines.slice();
      this.selectedRowKeys = [];
    },
    handleAddRow() {
      this.dataListJournalLines.push({
        key: this.dataListJournalLines.length,
        accountId: "",
        accountCode: "",
        code: "",
        description: "",
        debit: 0,
        credit: 0,
        no: this.dataListJournalLines.length + 1,
      });
      this.dataListJournalLines.push({
        no: this.dataListJournalLines.length + 1,
        key: this.dataListJournalLines.length,
        accountId: "",
        accountCode: "",
        code: "",
        description: "",
        debit: 0,
        credit: 0,
      });
    },
    onchangeCoa(meta: ResponseAccountingAccount, record: RowTable) {
      record.code = meta.code;
    },
  },
});
