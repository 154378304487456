var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        title:
          _vm.mode === "create"
            ? _vm.$t("lbl_create_general_journal")
            : _vm.$t("lbl_update_general_journal")
      }
    },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "formCreateJournal",
                attrs: {
                  rules: _vm.formRules,
                  model: _vm.form,
                  "label-align": "left"
                }
              },
              "a-form-model",
              _vm.formItemLayout,
              false
            ),
            [
              _vm.isSubmitted
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_document_number"),
                        prop: "documentNumber"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: _vm.$t("lbl_document_number"),
                          "read-only": ""
                        },
                        model: {
                          value: _vm.form.documentNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "documentNumber", $$v)
                          },
                          expression: "form.documentNumber"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isSubmitted
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_document_reference"),
                        prop: "documentReference"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: _vm.$t("lbl_document_reference"),
                          "read-only": ""
                        },
                        model: {
                          value: _vm.form.documentReference,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "documentReference", $$v)
                          },
                          expression: "form.documentReference"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formProps.date.label),
                    prop: "date"
                  }
                },
                [
                  _c("a-date-picker", {
                    staticClass: "w-100",
                    attrs: {
                      placeholder: _vm.$t(_vm.formProps.date.placeholder),
                      format: _vm.DEFAULT_DATE_FORMAT,
                      "disabled-date": _vm.disabledDate,
                      "allow-clear": ""
                    },
                    model: {
                      value: _vm.form.date,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "date", $$v)
                      },
                      expression: "form.date"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formProps.description.label),
                    prop: "description"
                  }
                },
                [
                  _c("a-textarea", {
                    attrs: {
                      placeholder: _vm.$t(
                        _vm.formProps.description.placeholder
                      ),
                      "allow-clear": "",
                      rows: { minRows: 1, maxRows: 4 }
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description"
                    }
                  })
                ],
                1
              ),
              _vm.isSubmitted
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_source"), prop: "source" } },
                    [
                      _c("span", { staticClass: "ant-form-text" }, [
                        _vm._v(_vm._s(_vm.form.source || "-"))
                      ])
                    ]
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formProps.attachment.label),
                    prop: "attachment"
                  }
                },
                [
                  _vm.attachment && _vm.form.attachment
                    ? [
                        _vm.isImg(_vm.form.attachment)
                          ? [
                              _c(
                                "a-space",
                                [
                                  _c(
                                    "a-button",
                                    {
                                      staticClass: "p-0",
                                      attrs: { type: "link" },
                                      on: { click: _vm.toggleShowImage }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.form.attachment) + " "
                                      )
                                    ]
                                  ),
                                  _vm.isSubmitted
                                    ? _c("a-button", {
                                        attrs: {
                                          icon: "delete",
                                          type: "danger",
                                          size: "small"
                                        },
                                        on: { click: _vm.deleteAttachment }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("VueEasyLightbox", {
                                attrs: {
                                  visible: _vm.showImage,
                                  index: 0,
                                  imgs: _vm.attachment
                                },
                                on: { hide: _vm.toggleShowImage }
                              })
                            ]
                          : [
                              _c(
                                "a-space",
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          _vm.attachment ||
                                          "javascript:void(0)",
                                        target: "_blank",
                                        rel: "nooppener noreferrer",
                                        download: _vm.form.attachment
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.form.attachment))]
                                  ),
                                  _vm.isSubmitted
                                    ? _c("a-button", {
                                        attrs: {
                                          icon: "delete",
                                          type: "danger",
                                          size: "small"
                                        },
                                        on: { click: _vm.deleteAttachment }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                      ]
                    : _vm.isModeCreate || _vm.isModeUpdate
                    ? [
                        _c("CUpload", {
                          on: {
                            success: _vm.onUploadDone,
                            deleted: _vm.onUploadRemoved
                          }
                        })
                      ]
                    : [
                        _c(
                          "span",
                          [
                            _c("a-icon", { attrs: { type: "file" } }),
                            _vm._v(" " + _vm._s(_vm.$t("lbl_file_not_found")))
                          ],
                          1
                        )
                      ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { sm: 24, md: 12 } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "danger", icon: "delete" },
                          on: { click: _vm.showConfirmation }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { icon: "plus", type: "primary" },
                          on: { click: _vm.handleAddRow }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-table",
                    {
                      attrs: {
                        "data-source": _vm.dataListJournalLines,
                        pagination: {
                          showTotal: function(total) {
                            return _vm.$t("lbl_total_items", { total: total })
                          }
                        },
                        scroll: { x: "calc(750px + 50%)", y: 520 },
                        loading: _vm.loading.table,
                        "row-selection": {
                          selectedRowKeys: _vm.selectedRowKeys,
                          onChange: _vm.onSelectChange
                        }
                      }
                    },
                    [
                      _c(
                        "a-table-column",
                        {
                          key: "no",
                          attrs: { width: "75px", "data-index": "no" }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_number_short")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "accountId",
                          attrs: { "data-index": "accountId" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _c("CSelectAccountingAccount", {
                                    attrs: {
                                      "label-key": ["description"],
                                      "for-list": false,
                                      "prop-account-name": record.accountCode,
                                      "prop-search-by": "isParent~false"
                                    },
                                    on: {
                                      "on-select": function(ref) {
                                        var meta = ref.meta

                                        return _vm.onchangeCoa(meta, record)
                                      }
                                    },
                                    model: {
                                      value: record.accountId,
                                      callback: function($$v) {
                                        _vm.$set(record, "accountId", $$v)
                                      },
                                      expression: "record.accountId"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_account_name")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "code",
                          attrs: { "data-index": "code", width: "130px" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(code) {
                                return [_vm._v(" " + _vm._s(code || "-") + " ")]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_code")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "debit",
                          attrs: { "data-index": "debit" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _c("a-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      formatter: _vm.formatterNumber,
                                      parser: _vm.reverseFormatNumber,
                                      precision: _vm.DECIMAL_PLACES_CURRENCY,
                                      min: 0,
                                      disabled: record.credit > 0
                                    },
                                    model: {
                                      value: record.debit,
                                      callback: function($$v) {
                                        _vm.$set(record, "debit", _vm._n($$v))
                                      },
                                      expression: "record.debit"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_debit")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "credit",
                          attrs: { "data-index": "credit" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _c("a-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      formatter: _vm.formatterNumber,
                                      parser: _vm.reverseFormatNumber,
                                      min: 0,
                                      precision: _vm.DECIMAL_PLACES_CURRENCY,
                                      disabled: record.debit > 0
                                    },
                                    model: {
                                      value: record.credit,
                                      callback: function($$v) {
                                        _vm.$set(record, "credit", _vm._n($$v))
                                      },
                                      expression: "record.credit"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_credit")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "description",
                          attrs: { "data-index": "description" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _c("a-textarea", {
                                    attrs: {
                                      placeholder: _vm.$t("lbl_type_here"),
                                      rows: { minRows: 1, maxRows: 4 },
                                      "allow-clear": ""
                                    },
                                    model: {
                                      value: record.description,
                                      callback: function($$v) {
                                        _vm.$set(record, "description", $$v)
                                      },
                                      expression: "record.description"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_description")))]
                          )
                        ]
                      ),
                      _c("template", { slot: "footer" }, [
                        _c(
                          "div",
                          {
                            staticClass: "text-right",
                            class: { "validate-error": _vm.isJournalBalance }
                          },
                          [
                            _c("span", { staticClass: "ant-form-text" }, [
                              _vm._v(
                                _vm._s(_vm.$t("lbl_debit")) +
                                  " : " +
                                  _vm._s(_vm._f("currency")(_vm.getTotalDebit))
                              )
                            ]),
                            _c("span", { staticClass: "ant-form-text" }, [
                              _vm._v(
                                _vm._s(_vm.$t("lbl_credit")) +
                                  " : " +
                                  _vm._s(_vm._f("currency")(_vm.getTotalCredit))
                              )
                            ])
                          ]
                        )
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-divider"),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleBack } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                      ]),
                      _vm.isSubmitted
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.posted,
                                disabled: _vm.isPosted
                              },
                              on: { click: _vm.handlePosted }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_posted")) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            icon: "save",
                            type: "primary",
                            loading: _vm.loading.save,
                            disabled: _vm.isJournalBalance || _vm.isPosted
                          },
                          on: { click: _vm.handleSave }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }